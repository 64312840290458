<template>
  <select
    v-bind="context.attrs"
    :id="context.id"
    :name="context.node.name"
    :value="context._value"
    :class="context.classes.input"
    :disabled="context.disabled ? true : undefined"
    @blur="context.handlers.blur"
    @change="(event) => context.node.input(event.target.value)"
  >
    <option
      v-if="context.attrs.placeholder"
      value=""
      disabled
      selected
    >
      {{ context.attrs.placeholder }}
    </option>
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="option[keyField]"
    >
      {{ option[labelField] }}
    </option>
  </select>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const node = computed(() => props.context.node);
const options = ref(node.value.props.options ?? []);
const labelField = ref(node.value.props.labelField ?? 'title');
const keyField = ref(node.value.props.keyField ?? 'id');
</script>
